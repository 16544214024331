import React, {useState, useEffect, useCallback, useRef} from "react";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from '@mui/icons-material/Delete';
import { styled } from '@mui/material/styles';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Container from '@mui/material/Container'


const ToSend = (params) => {
    const {file, remove} = params
    return <div style={{
        border: '1px solid grey',
        backgroundColor: '#444',
        borderRadius: 3,
        padding: 3,
        fontSize: '0.9em',
        paddingRight: 90,
        margin: 0,
        width: '100%',
        position: 'relative',
        fontStyle: 'italic',
        verticalAlign: 'center',
        whiteSpace: 'nowrap',
        overflow:'hidden',
        textOverflow:'ellipsis',
    }}>{file.name} <sup style={{position:'absolute', top:4, right:30}}>{file.size>>10} KB</sup>
        <IconButton aria-label="delete" sx={{position:'absolute', right:-4, bottom:-2}} onClick={remove}>
          <DeleteIcon />
        </IconButton>
    </div>
}

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});


export default function FileTx({dropZoneRef, toSend, setToSend}) {
    const [drag, setDrag] = useState(false)
    let dragCounter = 0

    const addFiles = useCallback(files => {
        if (!files || files.length === 0) return
        [...files].forEach(f => f.key = Math.random())
        setToSend([...toSend, ...files])
    }, [toSend, setToSend])

    const handleDragIn = useCallback(e => {
        dragCounter++
        setDrag(e.dataTransfer.items && e.dataTransfer.items.length)
    }, [setDrag, dragCounter]);

    const handleDragOut = useCallback(() => {
        dragCounter--
        setDrag(!!dragCounter)
    }, [setDrag, dragCounter]);

    const handleDrop = useCallback(e => {
        e.preventDefault()
        e.stopPropagation()
        setDrag(false)
        addFiles(e.dataTransfer.files)
        return false
    }, [addFiles, setDrag])

    const handleDrag = useCallback(e => {
        e.preventDefault()
        e.stopPropagation()
    }, [])

    const handlePaste = useCallback(event => {
        const items = (event.clipboardData || event.originalEvent.clipboardData).items;
        const add = [...items].filter(item => item.kind === 'file')
        addFiles(add.map(item => item.getAsFile()))
        if (add.length) {
            // files have been pasted, dont now paste the text-filenames of these files into the message box,
            // that would be silly.
            event.preventDefault()
            event.stopPropagation()
        }
    }, [addFiles]);

    const removeFile = file => setToSend(toSend.filter(f => f !== file))

    useEffect(() => {
        const ref = dropZoneRef.current
        if (!ref) return
        ref.addEventListener('dragenter', handleDragIn)
        ref.addEventListener('dragleave', handleDragOut)
        ref.addEventListener('dragover', handleDrag)
        ref.addEventListener('drop', handleDrop)
        ref.addEventListener('paste', handlePaste)
        return () => {
            try {
                ref.removeEventListener('dragenter', handleDragIn)
                ref.removeEventListener('dragleave', handleDragOut)
                ref.removeEventListener('dragover', handleDrag)
                ref.removeEventListener('drop', handleDrop)
                ref.removeEventListener('paste', handlePaste)
            } catch (e) {
                console.error(e)
            }
        }
    }, [handleDrop, handleDrag, dropZoneRef, handleDragIn, handleDragOut, handlePaste])

    const handleChange = useCallback(e => {
        e.preventDefault()
        e.stopPropagation()
        setDrag(false)
        addFiles(fileinput.current.files)
        return false
    }, [addFiles])

    const fileinput = useRef()
    useEffect(() => {
        if (!fileinput.current) return
        fileinput.current.addEventListener('change', handleChange)
        return () => {
            try {
                fileinput.current.removeEventListener('change', handleChange)
            } catch (e) {
                console.error(e)
            }
        }
    }, [fileinput, handleChange])

    const uploadClick = () => fileinput.current.click()

    return <div>
        {drag ? <div onClick={_ => setDrag(false)} style={{
            height: '200px', position: 'absolute', bottom: 5, left: 5, right: 5,
            border: '1px solid #3a3', borderRadius: '10px', backgroundColor: '#161a'
        }}/> : ''}
        <Container maxWidth="sm" disableGutters={true} sx={{backgroundColor: '#111', maxHeight:'200px', overflowY:'auto'}}>
            {toSend.map(f => <ToSend key={f.key} file={f} remove={() => removeFile(f)}/>)}
        </Container>
        <IconButton aria-label="upload" sx={{position:'absolute', right:4, bottom:3, zIndex:1}} onClick={uploadClick}>
          <VisuallyHiddenInput type="file" multiple={true} ref={fileinput}/>
          <CloudUploadIcon />
        </IconButton>
    </div>
}
