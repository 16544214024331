import {styled} from "@mui/system";

const PendingConnection = styled('div')(() => ({
    border: '1px solid grey',
    textAlign: 'center',
    backgroundColor: '#333c',
    borderRadius: 3,
    padding: 3,
    margin: 0,
    width: '40%',
    left: '30%',
    position: 'relative',
    verticalAlign: 'center',
}));

export default PendingConnection
