import create from "zustand";

export const useMsgStore = create((set) => ({
    user_id: null,
    set_user_id: (user_id) => set(() => ({user_id})),
    msgs: {}, // permanent state store for all rooms messages
    roomid: null, // current room id
    rmsgs: [], // IDs of current room messages
    rx_pending: [], // {id, msg, bin_parts, bin_mime, rx_parts, rx_at, ...} pending receiving messages from-server
    tx_pending: [], // tx_pending sending messages to-server
    paused: [], // list of message ids that are paused for tx/rx operations
    file_tx_parts: [], // {msg, num, msg_bin} transfers to-server in progress
    file_rx_parts: {}, // {msgid: {num: {msg_bin}}} file transfers from-server in progress (received parts)
    file_waiting_parts: {}, // {msgid: {num: {msg_bin}}} file transfers from-server in progress (waiting for parts)
    temp_files: [],
    last_msgs: {}, // Last received messages per room keyed on room id
    unread: {}, // count of messages that are unread, keyed on roomid (displayed in room list)
    read: {},  // id of last read message, keyed on roomid
    set_unread: (unread) => set(() => ({unread})),
    set_read: (read) => set(() => {
        // TODO: if last_msg matches the read value, set unread to 0
        return {read}
    }),
    mark_read: (msg) => set((state) => ({
        read: {...state.read, [msg.room]: msg.id},
        unread: {...state.unread, [msg.room]: 0},
    })),
    store_file: (f) => set((state) => ({temp_files: [...state.temp_files, f]})),

    set_bin_state: (bin_state) => set((state) => {
        const msgs = {...state.msgs}
        try {
            msgs[bin_state.msg].bin_state = bin_state.bin_state
        } catch (e) {
            console.error('error setting bin_state', e, bin_state)
            return {}
        }
        return {msgs}
    }),

    /**
     * Pause an upload/download to allow others to have priority
     */
    pause: (msgid) => set((state) => ({
        paused: [...state.paused, msgid]
    })),

    /**
     * Unpause an upload/download
     */
    unpause: (msgid) => set((state) => {
        const paused = [...state.paused]
        const index = paused.indexOf(msgid)
        if (index > -1)
            paused.splice(index, 1)
        console.log('paused', paused)
        return {paused}
    }),

    /**
     * Received a file_part, or if msg_bin is empty, then registering a placeholder file_part to be received
     */
    rx_file_part: (bin_msg) => set((state) => {  // {msg, num, msg_bin}
        // add the new rx_file_part to the list
        // bin_msg is the object with new components in it
        // msg_bin is the property of the object that contains binary data - dont get confused!
        const file_rx_parts = {...state.file_rx_parts}
        if (typeof(file_rx_parts[bin_msg.msg]) === 'undefined') {
            file_rx_parts[bin_msg.msg] = {}
            console.log(bin_msg.msg, 'received first bin_part', bin_msg)
        }
        file_rx_parts[bin_msg.msg][bin_msg.num] = bin_msg
        const rx_parts = file_rx_parts[bin_msg.msg]

        const expected_parts = state.msgs[bin_msg.msg].bin_parts

        const file_waiting_parts = {...state.file_waiting_parts}
        if (typeof file_waiting_parts[bin_msg.msg] === 'undefined') {
            console.error('received unexpected file part', bin_msg)
            return {}
        }
        if (typeof file_waiting_parts[bin_msg.msg][bin_msg.num] === 'undefined') {
            console.error('received part already', bin_msg)
            return {}
        }
        delete file_waiting_parts[bin_msg.msg][bin_msg.num]

        let rx_pending = [...state.rx_pending]
        const index = rx_pending.findIndex(msg => msg.id === bin_msg.msg)
        if (index === -1) {
            console.error('file part missing?', bin_msg)
            return
        }
        const pending_item = rx_pending[index]

        const got_parts = Object.keys(rx_parts).length
        const missing = expected_parts - got_parts

        // update number of parts received
        pending_item.rx_parts = got_parts

        if (!missing) {
            // file is complete, download finished
            // remove rx_pending object altogether
            rx_pending.splice(index, 1)

            // file is complete, compile it and move to stored files
            const parts = []
            for (let i=0; i< pending_item.bin_parts; i++) {
                parts.push(rx_parts[i])
            }

            // remove all received parts for this message from the pending parts list since its all here
            delete file_rx_parts[bin_msg.msg]
            delete file_waiting_parts[bin_msg.msg]

            parts.sort((a, b) => a.num < b.num ? -1 : 1)
            const data = parts.map(p => p.msg_bin)
            console.log(bin_msg.msg, 'file downloaded:', parts.length, {msg: pending_item, data: new Blob(data)})
            state.store_file({msg: pending_item, data: new Blob(data, {type:pending_item.bin_mime})})
        }

        // TODO notify user file is downloaded

        return {file_rx_parts, rx_pending, file_waiting_parts}
    }),

    /**
     * Queueing a part of a binary file to be uploaded
     */
    queue_tx_file_parts: (txmsg, file) => set((state) => {
        const parts = {}
        console.log(txmsg.id, 'queuing file parts for upload')
        for (let i = 0 ; i < txmsg.bin_parts ; i++) {
            parts[i] = {
                sent_at: 0,
            }
        }
        const part = {
            msg: txmsg,
            data: file,
            parts,
        }

        return {file_tx_parts: [...state.file_tx_parts, part]}
    }),

    /**
     * Sending a message, which may refer to a binary file, we must await the 'ack' from the server before
     * adding this message to the internal message stack, it currently has no ID
     * once we receive the ack (and the msg ID) we can start sending binary file parts to the server
     */
    tx: (msg) => set((state) => {
        return {
            tx_pending: [...state.tx_pending, msg],
        }
    }),

    /**
     * Registering that we have requested and are awaiting a file transfer
     */
    rx: (msg) => set((state) => {

        console.log('registering new rx file transfer', msg.id)
        const file_waiting_parts = {...state.file_waiting_parts}
        const nuveau = file_waiting_parts[msg.id] = {}

        for (let i = 0; i < msg.bin_parts; i++) {
            nuveau[i] = {
                msg: msg.id,
                num: i,
                msg_bin: null
            }
        }

        return {rx_pending: [...state.rx_pending, msg], file_waiting_parts}
    }),

    /**
     * Register ack for part of a file which was sent but unconfirmed if received
     */
    ack_part: (ackmsg) => set((state) => {
        // file-part sent by us confirmed received by the server
        const file_tx_parts = [...state.file_tx_parts]
        const txmsg = file_tx_parts.find(p => p.msg.id === ackmsg.msg)

        // part received, remove from the pending to-send list
        delete txmsg.parts[ackmsg.part]

        const remaining_parts = Object.keys(txmsg.parts).length
        const tx_pending = [...state.tx_pending]
        const pendingIndex = tx_pending.findIndex(pending => pending.id === ackmsg.msg)
        if (pendingIndex === -1) {
            console.error('missing tx pending', ackmsg, tx_pending)
            return {}
        }
        if (pendingIndex > -1) {
            const pending = tx_pending[pendingIndex]
            // console.log('ack part for file transfer', msg.id, msg.bin_parts, remaining_parts)
            // update the tx_parts property to track the file upload status
            pending.tx_parts = pending.bin_parts - remaining_parts
            // remove the tx_pending entry if file upload is complete
            if (!remaining_parts) {
                console.log(ackmsg.msg, 'file transfer complete')
                tx_pending.splice(pendingIndex, 1)
            }
        }
        return {file_tx_parts, tx_pending}
    }),

    /**
     * Received ack for previously sent message
     * @param msg message object
     */
    ack: (msg) => set((state) => {

        /**
         * filter through the pending messages and update any with missing msg.id values, since when we try to
         * first upload a file, we dont have a msg.id to associate it with, it has to be issued by the server.
         */

        const tx_pending = state.tx_pending.filter(p => {
            if (p.id)
                return true
            if (p.tx_at === msg.tx_at && p.room === msg.room) {
                // tx_at, rx_at, id, sender
                // add the missing message field, since the message is the only part not sent back to the sender
                msg.msg = p.msg
                state.add_msg(msg)
                if (!p?.bin_parts) {
                    // only remove it from the pending msgs if it has no bin_parts, otherwise we're waiting for
                    // the corresponding parts to arrive for this message.
                    return false
                }
            }
            return true
        })

        // we only receive acks for messages we sent, so automatically this one must have been read by us
        state.mark_read(msg)

        window.dispatchEvent(
            new CustomEvent(`ack.${msg.room}.${msg.tx_at}`, { detail: {msgid: msg.id}})
        )
        return {tx_pending}
    }),

    /**
     * Change the current room, rearrange the rmsgs list to show messages for the new room
     * @param roomid
     */
    setRoomId: (roomid) => set((state) => {
        // roomid unchanged, ignore
        if (state.roomid === roomid) return {}
        const unique = (value, index, array) => array.indexOf(value) === index
        const rmsgs = Object.keys(state.msgs)
            .filter(k => state.msgs[k].room === roomid)
            .map(k => state.msgs[k].id)
            .filter(unique)
            .sort((a, b) => a - b)
        return {roomid, rmsgs}
    }),
    add_msg_bulk: (msgs) => set((state) => {
        msgs.forEach(msg => state.add_msg(msg, true))
        return {}
    }),
    add_msg: (msg, bulk=false) => set((state) => {
        // message already received/processed, ignore
        if (typeof state.msgs[msg.id] !== 'undefined')
            return {}

        if (msg?.thumb_for) {
            // auto-download thumbnails
            console.log('downloading thumb', msg.thumb_for, msg)
            if (state.temp_files.indexOf(msg.thumb_for) === -1) {
                state.rx(msg)
            }
        }

        const ch = {msgs: {...state.msgs, [msg.id]: msg}}

        // If the thumb_for is present, find the original image and add the 'thumb' link so it can be linked
        if (typeof msg.thumb_for !== 'undefined' && msg.thumb_for) {
            const orig = ch.msgs[msg.thumb_for]
            if (typeof orig !== 'undefined') {
                orig.thumb = msg
                msg.thumb_for = orig
            }
        }
        // If the thumb is present, find the thumb image and add the 'thumb_for' link so it can be linked
        if (typeof msg.thumb !== 'undefined' && msg.thumb) {
            const orig = ch.msgs[msg.thumb]
            if (typeof orig !== 'undefined') {
                orig.thumb_for = msg
                msg.thumb = orig
            }
        }

        if (!state.last_msgs.hasOwnProperty(msg.room) || state.last_msgs[msg.room].id < msg.id) {
            // update last_message indicator for the given room
            ch.last_msgs = {...state.last_msgs, [msg.room]: msg}
        }

        // update unread message counters
        if (bulk) {
            // adding bulk list of messages, do nothing
        }
        else if (msg.sender === state.user_id) {
            // if the new message was from the current user, dont mark it as unread
            //   this happens when user is signed in from multiple locations
        }
        else if (!state.unread.hasOwnProperty(msg.room)) {
            // previously had no unread messages for room, adding new unread marker
            ch.unread = {...state.unread, [msg.room]: 1}
            // console.log('UNREAD 1')
        }
        else if (state.read.hasOwnProperty(msg.room) && state.read[msg.room] >= msg.id) {
            // do nothing, message being added is older than latest one weve read
        }
        else {
            // previously had unread messages for room, incrementing the counter
            // console.log('UNREAD 2', msg.room, state.read[msg.room])
            ch.unread = {...state.unread, [msg.room]: 1 + state.unread[msg.room]}
        }

        if (state.roomid === msg.room) {
            const unique = (value, index, array) => array.indexOf(value) === index
            ch.rmsgs = [...state.rmsgs, msg.id].sort((a, b) => a - b).filter(unique)
        }
        return ch
    })
}))