import './App.css'
import Container from '@mui/material/Container'
import Toast from './Toast'
import CssBaseline from "@mui/material/CssBaseline"
import ThemeProvider from './ThemeProvider'
import {useToastStore} from "./Toast"
import {Routes, Route} from "react-router-dom"
import AppBar from './AppBar'
import {useServiceWorker} from './useServiceWorker'
import {useEffect, useState} from "react"
import {Button} from "@mui/material"
import {useLoginStore} from "./Login"
import {Helmet} from "react-helmet-async"
import ErrorBlock from './panel/ErrorBlock'
import WebSock from "./WebSock"
import Admins from "./panel/Admins"
import Acl from "./panel/Acl"
import Room from "./panel/Room"
import RoomList, {LoadRoomList} from "./panel/RoomList"
import { LicenseInfo } from '@mui/x-license-pro'
import create from "zustand";
import Box from "@mui/material/Box";

LicenseInfo.setLicenseKey('273bfd6937b58be14dca9ab6d25afdf5Tz02MzU3NSxFPTE3MTIyMjE5MjgxMTAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=');

export const useAppStore = create((set) => ({
    pageTitle: '',
    setPageTitle: (pageTitle) => set({ pageTitle }),
}))

export function canUser(method, path, permissions) {
    return permissions.some(p => {
        if (p.method === method || p.method === '*') {
            if (p.path === path || p.path === '*') {
                return true
            }
            else if (p.path.slice(-1) === '*' && p.path.slice(0, -1) === path.slice(0, p.path.length - 1)) {
                // match with wildcard suffix
                return true
            }
        }
        return false
    })
}

export default function App() {
    const infoToast = useToastStore((state) => state.showInfo)
    const { waitingWorker, showReload, reloadPage } = useServiceWorker()
    const loginstore = useLoginStore()
    const [links, setLinks] = useState([])
    const [routes, setRoutes] = useState([])
    const [webSocket, setWebSocket] = useState(<></>)
    const [roomlist, _] = useState(<RoomList/>)

    // decides when to show the toast
    useEffect(() => {
      if (showReload && waitingWorker) {
        infoToast(
            <div>
              New app version available!
              <Button style={{marginLeft:'5px'}} onClick={() => reloadPage()}>Reload</Button>
            </div>
          , 30_000)
      }
    }, [infoToast, waitingWorker, showReload, reloadPage])

    useEffect(() => {
        const links = [
            {name:'Rooms', link: '/'},
        ]
        const routes = []
        if (loginstore.user) {
            const mlinks = [
                ['Admins',    '/admins'],
                ['ACL',       '/acl'],
            ]
            Object.keys(mlinks).forEach(k => {
                const [name, link] = mlinks[k]
                if (canUser('GET', '#' + link, loginstore.permissions)) {
                    links.push({name, link})
                }
            })
            routes.push(<Route exact key="/acl" path="/acl" element={<Acl/>}/>)
            routes.push(<Route exact key="/admins" path="/admins" element={<Admins/>}/>)
            routes.push(<Route exact key="/r/:name" path="/r/:room" element={<Room/>}/>)
            routes.push(<Route exact key="/" path="/" element={roomlist}/>)
        } else {
            routes.push(<Route key="*"  path="*" element={<></>}/>)
        }
        setRoutes(routes)
        setLinks(links)
    }, [loginstore.permissions, setRoutes, setLinks])

    useEffect(() => {
        setWebSocket(<WebSock/>)
    }, [setWebSocket])

    return (
        <>
            <Helmet>
            </Helmet>
            <LoadRoomList/>
            <ThemeProvider>
                <ErrorBlock name="Appbar">
                    <AppBar links={links}/>
                </ErrorBlock>
                <CssBaseline/>
                <ErrorBlock name="Main panel">
                    <Box sx={{
                        position: 'absolute',
                        padding: 0,
                        margin: 0,
                        marginTop: '55px',
                        height: 'calc(100vh - 55px)',
                        overflowY: 'auto',
                        overflowX: 'hidden',
                        left: '0',
                        right: '0',
                    }}>
                        <Container sx={{position:'relative', height: 'auto', minHeight:'100%'}}>
                            <Routes>
                                {routes}
                            </Routes>
                        </Container>
                        <Toast/>
                    </Box>
                </ErrorBlock>
            </ThemeProvider>
            <ErrorBlock name="Websocket">
                {webSocket}
            </ErrorBlock>
        </>
    )
}
