import {useLoginStore} from "../Login";
import { styled } from '@mui/system';
import {useCallback, useEffect, useState} from "react";
import {useMsgStore} from "../utils/useMsgStore";
import FilePresentIcon from '@mui/icons-material/FilePresent';
import Box from "@mui/material/Box";
import PersonName from "./PersonName";
import create from "zustand";
import IconButton from "@mui/material/IconButton";
import PauseIcon from '@mui/icons-material/Pause';
import CircularProgressWithLabel from "../component/CircularProgressWithLabel"
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import DownloadIcon from '@mui/icons-material/Download';

const MsgItem = styled('div')(({from_me}) => ({
    background: 'none',
    textAlign: from_me ? 'right': 'left',
}));
const MsgInner = styled('div')(({ from_me, is_dm }) => ({
    margin: 1,
    [from_me ? 'marginLeft' : 'marginRight']: 60,
    backgroundColor: from_me ? '#8885' : '#8888',
    borderRadius: from_me ? '6px 6px 0px 6px' : '0px 6px 6px 6px',
    padding: '5px',
    [from_me ? 'paddingRight' : 'paddingLeft']: '60px',
    [is_dm && from_me ? 'borderRight' : null]: '4px solid #270',
    [is_dm && !from_me ? 'borderLeft' : null]: '4px solid #270',
    position: 'relative',
    display: 'inline-block',
    maxWidth: 'min(600px, 80vw)',
    minWidth: '60px',
    minHeight: '16px',
}))
const ClickMenu = styled('div')(({from_me}) => ({
    position: 'absolute',
    left: from_me ? -38 : null,
    right: from_me ? null : -38,
    width: 36,
    top: 0,
    textAlign: 'center',
    backgroundColor: from_me ? '#8885' : '#8888',
    borderRadius: '4px',
}));
const Progress = styled('div')(({from_me}) => ({
    position: 'absolute',
    left: from_me ? null : 9,
    right: from_me ? 9 : null,
    top: 9
}));
const MsgTime = styled('div')(({from_me}) => ({
    position: 'absolute',
    fontSize: '0.7em',
    bottom: 0,
    [from_me ? 'right' : 'left']: 5,
}));
const Dm = styled('div')(({from_me, is_dm}) => ({
    fontStyle: 'italic',
    fontSize: '0.8em',
    position: 'absolute',
    [from_me ? 'right' : 'left']: 6,
    top: from_me ? 1 : 0,
    display: is_dm ? 'block' : 'none',
}));
const MsgSender = styled('span')(() => ({
    display: 'inline-block',
    borderTop: '1px solid #666',
    borderLeft: '1px solid #666',
    padding: '2px 80px 0 10px',
    marginLeft: '1px',
    marginTop: '5px',
    borderRadius: '8px 0 0 0',
}));
const DayMarker = styled('div')(() => ({
    textAlign: 'center',
    backgroundColor: '#5554',
    margin:10,
    borderRadius: 8,
}));
const UnreadMarker = styled('div')(() => ({
    textAlign: 'center',
    margin:10,
    borderRadius: 8,
    borderBottom: '1px dashed #c90',
}));
const ImagePreview = styled('img')(() => ({
    position: 'fixed',
    zIndex: 2,
    left: '5vw',
    right: '5vw',
    bottom: '5vh',
    top: '5vh',
    height: '90vh',
    width: '90vw',
    objectFit: 'contain',
}))
const EmbedImg = styled('img')(() => ({
    display: 'block',
    maxHeight: '180px',
    maxWidth: 'min(450px, 50vw)',
    borderRadius: 8,
}))
const ImgPlaceholder = styled('div')(() => ({
    cursor: 'pointer',
    backgroundColor: '#fff1',
    borderRadius: 8,
    padding: '2px 4px',
    lineHeight: '100%'
}))
const FilePlaceholder = styled('div')(() => ({
    cursor: 'pointer',
    backgroundColor: '#fff1',
    borderRadius: 8,
    padding: '8px 4px',
    lineHeight: '100%'
}))
const RawMsg = styled('div')(({is_dm, bigga}) => ({
    whiteSpace: 'pre-wrap',
    overflowWrap: 'break-word',
    ...(is_dm ? {fontStyle:'italic', fontSize: '0.9em'} : {}),
    [bigga ? 'fontSize' : null]: 30,
}))


const useClickedMsgStore = create((set) => ({
    clicked: null,
    setClicked: (clicked) => set(() => ({clicked})),
}))


export default function Msg({msg, prev}) {
    const msgStore = useMsgStore()
    const {clicked, setClicked} = useClickedMsgStore()

    if (typeof(msg) === 'undefined' || !msg) {
        console.log('BAD MSG', msg)
        msg = {rx_at:0,msg:'',sender:-1,}
    }
    const {id} = useLoginStore()
    if (!msg.hasOwnProperty('rx_at')) {
        msg.rx_at = 0
    }

    const [dayMarker, setDayMarker] = useState(<></>)
    const sent = new Date(msg.rx_at * 1000)
    useEffect(() => {
        if (!prev) return
        const psent = new Date(prev.rx_at * 1000)
        if (psent.getDate() !== sent.getDate()) {
            setDayMarker(<DayMarker>{sent.toISOString().slice(0, 10)}</DayMarker>)
        }
    }, [])

    /**
     * determine if a local tempfile exists for this message, make it downloadable if so
     */
    const [tempFile, setTempFile] = useState(null)
    useEffect(() => {
        if (tempFile && msgStore.temp_files.indexOf(tempFile) > -1) {
            return // no change
        }
        const found = msgStore.temp_files.some(f => {
            if (f.msg.id === msg.id) {
                setTempFile(f)
                return true
            }
            return false
        })
        if (tempFile && !found)
             setTempFile(null)
    }, [tempFile, setTempFile, msg.id, msgStore.temp_files])

    const [thumbTempFile, setThumbTempFile] = useState(null)
    useEffect(() => {
        if (!msg?.thumb) {
            return
        }
        if (thumbTempFile && msgStore.temp_files.indexOf(thumbTempFile) > -1) {
            return // no change
        }
        const found = msgStore.temp_files.find(f => f.msg.id === msg.thumb.id)
        if (found) {
            setThumbTempFile(found)
        }
        if (thumbTempFile && !found) {
            // has been removed ??
            setThumbTempFile(null)
        }
    }, [thumbTempFile, setThumbTempFile, msg.id, msg?.thumb, msgStore.temp_files])

    /**
     * Progress bar for sending a file
     */
    const [txPending, setTxPending] = useState(null)
    useEffect(() => {
        if (txPending && msgStore.tx_pending.indexOf(txPending) > -1) {
            return // no change
        }
        const found = msgStore.tx_pending.find(p => p?.id === msg.id)
        if (found) {
            setTxPending(found)
            setTxPercent(0)
        } else if (txPending) {
            setTxPending(null)
        }
    }, [txPending, setTxPending, msg.id, msgStore.tx_pending])

    /**
     * Progress bar for receiving a file
     */
    const [rxPending, setRxPending] = useState(null)
    useEffect(() => {
        if (rxPending && msgStore.rx_pending.indexOf(rxPending) > -1) {
            return // no change
        }
        const found = msgStore.rx_pending.find(p => p.id === msg.id)
        if (found) {
            setRxPending(found)
        } else if (rxPending) {
            setRxPending(null)
        }
    }, [rxPending, setRxPending, msg.id, msgStore.rx_pending])

    const [isImage, setIsImage] = useState(false)
    useEffect(() => {
        setIsImage(msg.bin_mime && msg.bin_mime.match(/^image\//))
    }, [msg.bin_mime])

    /**
     * Determine if theres a local image cached for this file, if its an image file.
     * Will use the image file as an image source if its downloaded and present.
     * TODO: thumbnail for larger images.
     */
    const [fileSrc, setFileSrc] = useState('')
    const [thumbSrc, setThumbSrc] = useState('')
    useEffect(() => {
        if (!msg.bin_parts || !msg.bin_mime) return
        const blob = tempFile?.data
        const urlCreator = window.URL || window.webkitURL;
        let fileUrl = null
        let thumbFileUrl = null
        if (blob instanceof Blob) {
            const file = new File([blob], msg.bin_name, {type:msg.bin_mime})
            fileUrl = urlCreator.createObjectURL(file)
            setFileSrc(fileUrl)
            if (isImage) {
                setThumbSrc(fileUrl)
            }
        }
        else if (isImage) {
            if (msg.thumb) {
                const blob = thumbTempFile?.data
                if (blob instanceof Blob) {
                    const file = new File([blob], msg.thumb.bin_name, {type: msg.thumb.bin_mime})
                    thumbFileUrl = urlCreator.createObjectURL(file)
                    setThumbSrc(thumbFileUrl)
                }
            }
        }

        return () => {
            fileUrl && urlCreator.revokeObjectURL(fileUrl)
            thumbFileUrl && urlCreator.revokeObjectURL(thumbFileUrl)
        }
    }, [msg, msg.thumb, isImage, setFileSrc, setThumbSrc, tempFile?.data, thumbTempFile?.data])

    const [rxPercent, setRxPercent] = useState(-1)
    const [txPercent, setTxPercent] = useState(-1)

    useEffect(() => {
        if (!txPending && tempFile?.data)
            setRxPercent(100)
    }, [tempFile?.data])

    /**
     * When a file is clicked, either download it from server to the browser, or download it from the browser
     * to the local machine if downloaded.
     * TODO: show hover popup buttons to download/delete/preview a file
     */
    const [imagePreviewModal, setImagePreviewModal] = useState(<></>)
    const fileClick = useCallback(() => {
        const fdata = tempFile?.data
        if (typeof(fdata) === 'undefined' || !fdata) {
            // file hasnt been downloaded yet, need to request the transfer
            // request first 10 parts of the file
            // once some of the parts are received, we request another 10 parts, and so on until all parts received

            if (!rxPending) {
                msgStore.rx(msg)
                setRxPercent(0)
            }
            return
        }
        if (isImage && fileSrc) {
            setImagePreviewModal(<ImagePreview src={fileSrc} onClick={() => setImagePreviewModal(<></>)}/>)
        }
    }, [msg, rxPending, setRxPercent, fileSrc, setImagePreviewModal, msgStore.rx, tempFile?.data])

    /**
     * Display the progress bar if the file associated with this message upload or download is in progress
     */
    useEffect(() => {
        const pend = txPending ? txPending : rxPending
        const done_parts = txPending ? pend?.tx_parts : pend?.rx_parts
        const setPercent = txPending ? setTxPercent : setRxPercent
        const percent = txPending ? txPercent : rxPercent
        if (percent === 100) {
            return
        }
        if (pend) {
            const pc = Math.round((100 / pend.bin_parts) * done_parts)
            setPercent(Number.isNaN(pc) ? 0 : pc)
        } else if (!txPending && tempFile?.data) {
            setPercent(100)
        }
    }, [msg.id, txPending, rxPending, txPending?.tx_parts, rxPending?.rx_parts, setRxPercent, setTxPercent, rxPercent, txPercent])

    /**
     * unread indicator to appear when there are unread messages for the room.
     * also blocks showing the unreadindicator if the unread message was sent by the current user.
     */
    const [unreadIndicator, setUnreadIndicator] = useState(<></>)
    useEffect(() => {
        if (!prev) return
        if (msgStore.read[msg.room] === prev.id) {
            setUnreadIndicator(<UnreadMarker/>)
        } else {
            setUnreadIndicator(<></>)
        }
    }, [msgStore.read[msg.room]])

    /**
     * Use an image component to indicate a file is attached to this message, whether its downloaded or not.
     */
    const [showThumb, setShowThumb] = useState(true)
    const [img, setImg] = useState(<></>)
    const [progress, setProgress] = useState(<></>)
    useEffect(() => {
        if (!msg.bin_name) {
            return
        }
        let percent = txPending ? txPercent : rxPercent
        let bs = msg?.bin_state ? ` (${msg.bin_state})` : ''
        const dragStart = e => {
            e.dataTransfer.clearData()
            e.dataTransfer.setData("DownloadURL", [msg.bin_mime, msg.bin_name, fileSrc].join(':'));
        }
        if (thumbSrc) {
            bs = ''
        }
        let draggable = false,
            onDragStart = null,
            onClick = null
        if (percent === 100) {
            bs = ''
        }
        if (fileSrc || !rxPending) {
            // file was uploaded by us, or file is fully downloaded
            onClick = fileClick
        }
        if (!thumbSrc && fileSrc) {
            draggable = true
            onDragStart = dragStart
        }
        if (percent > -1) {
            setProgress(<CircularProgressWithLabel value={percent}/>)
        }
        const size = msg.bin_size>>10 ? `${msg.bin_size>>10} KB` : `${msg.bin_size} Bytes`

        if (thumbSrc && showThumb) {
            setImg(
                <ImgPlaceholder {...{draggable, onDragStart, onClick}}>
                    <EmbedImg draggable={true} src={thumbSrc} onDragStart={dragStart}/>
                    <sub><i>{msg.bin_name} ({size}){bs}</i></sub>
                </ImgPlaceholder>
            )
        } else {
            setImg(<FilePlaceholder {...{draggable, onDragStart, onClick}}>
                <FilePresentIcon/>
                <sup><i>{msg.bin_name}<br/>{size}{bs}</i></sup>
            </FilePlaceholder>)
        }
    }, [msg, msg.bin_state, fileSrc, showThumb, thumbSrc, txPending, rxPercent, txPercent, fileClick])

    const emojis_regex = /^[\p{Extended_Pictographic}\u{1F3FB}-\u{1F3FF}\u{1F9B0}-\u{1F9B3}]+$/u
    const bigga = msg.msg.match(emojis_regex)
    const is_dm = msg.dm_for
    const from_me = id === msg.sender

    const [sender, setSender] = useState(<></>)
    useEffect(() => {
        if (from_me) return
        if (prev && prev.sender === msg.sender) return
        setSender(<MsgItem><MsgSender {...{from_me}}><PersonName person={msg.sender}/></MsgSender></MsgItem>)
    }, [])

    const [paused, setPaused] = useState(false)
    useEffect(() => {
        setPaused(msgStore.paused.indexOf(msg.id) > -1)
    }, [msgStore.paused, setPaused])

    const pauseClick = useCallback(() => {
        paused ? msgStore.unpause(msg.id) : msgStore.pause(msg.id)
    }, [paused])

    const percent = txPending ? txPercent : rxPercent
    const showPauseBtn = msg.bin_name && percent > -1 && percent < 100

    const canDownload = !!tempFile?.data
    const downloadClick = useCallback(() => {
        if (!canDownload) return
        const element = document.createElement('a')
        const fdata = tempFile?.data
        const reader = new FileReader();
        reader.addEventListener("load", () => {
            element.setAttribute('href', reader.result)
            element.setAttribute('download', msg.bin_name)
            element.style.display = 'none'
            document.body.appendChild(element)
            element.click()
            document.body.removeChild(element)
        }, false);
        reader.readAsDataURL(fdata);
    })

    const is_clicked = clicked === msg.id
    const actions = is_clicked ? <>
        {showPauseBtn ? <IconButton aria-label="cancel" onClick={_ => pauseClick()}>
            <PauseIcon color={paused?'success':'normal'}/>
        </IconButton> : ''}
        {thumbSrc ? <IconButton aria-label="smol" onClick={() => setShowThumb(!showThumb)}>
            {showThumb ? <VisibilityIcon /> : <VisibilityOffIcon />}
        </IconButton> : ''}
        {canDownload ? <IconButton aria-label="smol" onClick={downloadClick}>
            <DownloadIcon />
        </IconButton> : ''}
    </> : ''

    if (typeof msg.thumb_for === 'object') {
        // hide thumbnail msgs entirely, they should render within the msg they are a thumbnail for.
        return ''
    }

    const dev_mode = window.location.hostname.match(/\.demo\./) || window.location.hostname.match(/localhost/)

    return <MsgItem {...{from_me}} onMouseOver={_ => setClicked(msg.id)} onClick={_ => setClicked(msg.id)}>
        {imagePreviewModal}
        {unreadIndicator}
        {dayMarker}
        {sender}
        {/*<Box sx={{position:'absolute', color: '#555', textAlign:'center', width:'100%'}}>*/}
        {/*    /!*<br/>*!/*/}
        {/*    /!*thumb_for: {msg?.thumb_for?.id}*!/*/}
        {/*    /!*<br/>*!/*/}
        {/*    /!*thumb: {msg?.thumb?.id}*!/*/}
        {/*    {txPending ? 'tx': 'rx'}*/}
        {/*    {showPauseBtn ? 'pause': ''}*/}
        {/*    {actions ? 'actions': ''}*/}
        {/*    rxPercent:{rxPercent}*/}
        {/*    {percent > -1 ? '0+' : '-1'}*/}
        {/*</Box>*/}
        {dev_mode ? <sup style={{color:'#555'}}>{msg.id}</sup> : ''}
        <MsgInner {...{from_me, bigga, is_dm, progress}} >
            {actions ? <ClickMenu {...{from_me}}>{actions}</ClickMenu> : ''}
            <Progress {...{from_me}}>{progress}</Progress>
            <RawMsg {...{bigga, is_dm}}>
                {msg.msg}
            </RawMsg>
            <Dm {...{from_me, is_dm}}>private</Dm>

            <MsgTime {...{from_me}}>
                {sent.toISOString().slice(11,19)}
            </MsgTime>
            {img}
        </MsgInner>
    </MsgItem>
}
