import {useState, useEffect, useCallback, useRef} from "react";
import Stack from "@mui/material/Stack";
import {useNavigate, useParams} from "react-router-dom";
import TextField from "@mui/material/TextField";
import {useRoomStore} from "./RoomList";
import {useLoginStore} from "../Login";
import Msg from "./Msg";
import {useWebsocketStore} from "../WebSock";
import FileTx from "./FileTx";
import {useMsgStore} from "../utils/useMsgStore";
import {Button} from "@mui/material";
import {useAppStore} from "../App";
import PendingConnection from "./PendingConnection";
import TxArea from "./TxArea";
import IconButton from "@mui/material/IconButton";
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import {useServiceWorker} from "../useServiceWorker";
import Box from "@mui/material/Box";

function Muted({muted, unmute}) {
    if (!muted) return ''
    return <IconButton aria-label="muted" sx={{position:'absolute', right:2, top:0, zIndex:1}} onClick={unmute}>
      <VolumeOffIcon />
    </IconButton>
}

export default function Room() {
    const params = useParams()
    const roomid = parseInt(params.room)
    const msgStore = useMsgStore()
    const loginstore = useLoginStore()
    const {send} = useWebsocketStore()
    const {rooms} = useRoomStore()
    const appStore = useAppStore()
    const navigate = useNavigate()
    const [muted, setMuted] = useState(false)
    const { reloadPage } = useServiceWorker()
    const scrollable = useRef()
    const msgStack = useRef()

    useEffect(() => {
        rooms.forEach(r => r.id === roomid && appStore.setPageTitle(r.name))
    }, [rooms, roomid])

    useEffect(() => {
        msgStore.setRoomId(roomid)
        setMuted('1' === localStorage.getItem(`muteroom.${roomid}`))
    }, [])

    useEffect(() => {
        if (!roomid) return
        if (!rooms.filter(r => r.id === roomid).length) {
            // current user has left this room
            navigate('/')
        }
    }, [roomid, rooms])

    const [msgTxt, setMsgTxt] = useState('')
    const dropZoneRef = useRef()
    const [filesToSend, setFilesToSend] = useState([])
    const msgInput = useRef()

    const mute = useCallback(m => {
        setMuted(!!m)
        localStorage.setItem(`muteroom.${roomid}`, m?'1':'0')
    }, [])

    const ku = useCallback(e => {
        if (e.keyCode !== 13) return
        if (e.shiftKey) return  // shift-enter inserts newline
        setMsgTxt('')
    }, [setMsgTxt])

    const generateThumb = useCallback((txmsg, file) => {
        const canvas = document.createElement("canvas")
        const ctx = canvas.getContext('2d')
        if (!ctx) {
            throw new Error('Context not available')
        }
        const boundBox = [300, 300]
        const reader = new FileReader()
        reader.addEventListener('load', () => {
            const img = new Image()
            img.addEventListener('load', () => {
                const scaleRatio = Math.min(...boundBox) / Math.max(img.width, img.height)
                const w = img.width * scaleRatio
                const h = img.height * scaleRatio
                canvas.width = w
                canvas.height = h
                ctx.drawImage(img, 0, 0, w, h)
                canvas.toBlob(blob => {
                    const newfile = new File([blob], txmsg.bin_name+'.thumb', {type:txmsg.bin_mime})
                    const chunkSize = 1390
                    const thumb_msg = {...txmsg, msg: ''}
                    delete thumb_msg.id
                    thumb_msg.tx_at += Math.random()  // ensure all uploads have a unique timestamp
                    thumb_msg.bin_parts = Math.ceil(newfile.size / chunkSize)
                    // thumb_msg.bin_mime = txmsg.bin_mime
                    thumb_msg.thumb_for = txmsg.id
                    thumb_msg.bin_name = file.name + '.thumb'
                    thumb_msg.bin_size = newfile.size
                    thumb_msg.bin_state = 'pending'
                    window.addEventListener(`ack.${thumb_msg.room}.${thumb_msg.tx_at}`, e => {
                        thumb_msg.id = e.detail.msgid
                        msgStore.queue_tx_file_parts(thumb_msg, newfile)
                        msgStore.store_file({msg: thumb_msg, data: newfile})
                    }, { once: true })
                    msgStore.tx(thumb_msg)
                    send(JSON.stringify(thumb_msg))
                }, file.type)
            })
            img.src = window.URL.createObjectURL(file)
        })
        reader.readAsDataURL(file);
    }, [])

    /**
     * When typing into the message box, the return key triggers a send.
     * Messages with no file attachment and no message content cannot be sent.
     */
    const kd = useCallback((e) => {
        if (e.keyCode !== 13) return
        if (e.shiftKey) return  // shift-enter inserts newline
        if (!msgTxt && !filesToSend.length) return
        if (msgTxt === '/reload' || msgTxt === '/refresh') {
            reloadPage()
            setMsgTxt('')
            return
        }
        if (!send) return
        console.log('sending')
        let sendtext = msgTxt
        if (roomid === 0 && sendtext[0] !== '/')
            sendtext = '/' + sendtext
        const tx = {
            room: roomid,
            tx_at: new Date().getTime() / 1000,
            msg: sendtext,
            sender: loginstore.id,
        }
        if (msgTxt.slice(0, 1) === '/') {
            tx.dm_for = 1
        }

        if (msgTxt === '/mute') {
            mute(1)
            setMsgTxt('')
            return
        }
        if (msgTxt === '/unmute') {
            mute(0)
            setMsgTxt('')
            return
        }

        if (!filesToSend.length) {
            msgStore.tx(tx)
            send(JSON.stringify(tx))
            setMsgTxt('')
            return
        }

        let first = true;
        filesToSend.forEach(file => {
            const txmsg = first ? tx : {...tx, msg: ''}
            txmsg.tx_at += Math.random()  // ensure all uploads have a unique timestamp

            // using chunksize 1390 keeps the overall packet size down below 1500 which is a typical MTU
            // for packet sizes, meaning the packet shouldn't be segmented during delivery, which may
            // reduce reliability of delivery on BLOS
            const chunkSize = 1390

            console.log('fileToSend', 'tx_at', txmsg.tx_at, 'file', file.name)

            txmsg.bin_parts = Math.ceil(file.size / chunkSize)
            txmsg.bin_mime = file.type
            txmsg.bin_name = file.name
            txmsg.bin_size = file.size
            txmsg.bin_state = 'pending'

            const tt = setTimeout(() => {
                console.error('!!ack never received for file upload txmsg', txmsg.tx_at, file.name)
            }, 3000)

            window.addEventListener(`ack.${txmsg.room}.${txmsg.tx_at}`, e => {
                console.log(e.detail.msgid, 'got filetosend ack', txmsg.tx_at, file.name)
                clearTimeout(tt)
                txmsg.id = e.detail.msgid
                msgStore.queue_tx_file_parts(txmsg, file)
                msgStore.store_file({msg: txmsg, data: file})

                if (file.type.match(/^image\//)) {
                    generateThumb(txmsg, file)
                }
            }, { once: true })
            msgStore.tx(txmsg)
            send(JSON.stringify(txmsg))
            setMsgTxt('')
            first = false
        })
        setFilesToSend([])
    }, [roomid, msgTxt, filesToSend, send, setFilesToSend, setMsgTxt])

    /**
     * On page load, set Scrolled to FALSE so we know we haven't scrolled anywhere yet
     * When we load a bunch of messages into an empty panel, it wont have been able to scroll, but we should scroll
     * to the bottom once they are loaded to show the most recent messages.
     */
    const [scrolled, setScrolled] = useState(false)
    useEffect(() => {
        setScrolled(false)
        if (!scrollable.current) return
        scrollable.current.scrollTo({top: 999999, behavior: "instant"})
    }, [roomid, setScrolled, scrollable.current])

    /**
     * When we load messages or any content in the message list panel changes, we might need to scroll to get
     * the newest loaded messages in view. If the user is requesting more messages from history, these appear
     * at the top so we might need to scroll up to display them.
     */
    const [wsize, setWsize] = useState({})

    const resized = useCallback(() => {
        const docelem = document.documentElement
        const _scr = scrollable.current
        if (!_scr) return
        const viewportHeight = _scr.scrollHeight,
            distToTop = _scr.scrollTop,
            distToBottom = viewportHeight - docelem.clientHeight - distToTop;
        setWsize({viewportHeight, distToTop, distToBottom})
    }, [setWsize, scrollable.current])

    // if the window is resized, run the resizer
    useEffect(() => {
        window.addEventListener('resize', resized)
        return () => window.removeEventListener('resize', resized)
    }, [])

    // if the window content changes, run the resizer
    useEffect(() => {
        if (!msgStack.current)
            return
        const rs = new ResizeObserver(resized)
        rs.observe(document.documentElement)
        // rs.observe(document.body)
        console.log('observing msgStack for changes', msgStack.current)
        rs.observe(msgStack.current)
        return () => rs.disconnect()
    }, [setWsize, msgStack.current])

    useEffect(() => {
        const {distToTop, distToBottom} = wsize
        // scroll to bottom of page on each new message
        if (!scrolled) {
            const scrollTimer = setTimeout(() => {
                console.log('init scroll bottom')
                scrollable.current.scrollTo({top: 999999, behavior: "instant"})
                setScrolled(true)
            }, 10)
            return () => clearTimeout(scrollTimer)
        }
        if (distToBottom < 400) {
            // page doesnt seem loaded or very little content, just scroll down as far as we can
            const scrollTimer = setTimeout(() => {
                console.log('scroll bottom')
                scrollable.current.scrollTo({top: 999999, behavior: "instant"})
            }, 10)
            return () => clearTimeout(scrollTimer)
        }
        if (distToTop < 400) {
            const scrollTimer = setTimeout(() => {
                console.log('scroll top')
                scrollable.current.scrollTo({top: 0, behavior: "instant"})
            }, 10)
            return () => clearTimeout(scrollTimer)
        }
    }, [setScrolled, wsize, scrollable.current])

    /**
     * If theres only a small number of messages loaded on the screen, mark the latest one as read.
     */
    useEffect(() => {
        if (!send) return
        if (!msgStore.rmsgs.length) return
        const lastmsg = msgStore.last_msgs[roomid]
        const t = setTimeout(() => {
            msgStore.mark_read(lastmsg)
            send(JSON.stringify({mark_read: {msgid: lastmsg.id, roomid: roomid}}))
        }, 2000)
        return () => clearTimeout(t)
    }, [msgStore.rmsgs, msgStore.last_msgs[roomid], roomid])

    /**
     * Can't load more if there arent more to load
     */
    const [canLoadMore, setCanLoadMore] = useState(true)

    /**
     * This is the load-more button at the top of the room, to load more older messages
     */
    const loadmore = useCallback(() => {
        if (!send) return
        send(JSON.stringify({loadmore: {room: roomid, beforeid: Math.min(...msgStore.rmsgs)}}))
    }, [send, roomid, msgStore.rmsgs])

    /**
     * When we first load the room, request messages from the server if theres less than 20 messages in the room.
     */
    useEffect(() => {
        if (!send) return
        if (!canLoadMore) return
        if (msgStore.rmsgs.length < 20) {
            send(JSON.stringify({loadmore: {room: roomid}}))
        }
    }, [send, roomid, canLoadMore])

    /**
     * Determine whether more messages are loadable, since the 'first message id' is a property of the room
     */
    useEffect(() => {
        if (!rooms || !msgStore.rmsgs) return
        if (!canLoadMore) return
        rooms.forEach(r => {
            if (r.id === roomid && r.first_message === Math.min(...msgStore.rmsgs))
                setCanLoadMore(false)
        })
    }, [rooms, roomid, msgStore.rmsgs, setMsgTxt])

    return <Box ref={dropZoneRef}>
        <Box ref={scrollable} sx={{
            position: 'absolute',
            margin: 0,
            height: 'calc(100vh - 102px)',
            overflowY: 'auto',
            overflowX: 'hidden',
            left: 0,
            right: 0,
        }}>
            <Stack direction="row" justifyContent="center">
                { canLoadMore
                    ? <Button onClick={loadmore}>Load more…</Button>
                    : <em style={{display:'block', textAlign:'center'}}>No earlier messages</em>
                }
            </Stack>
            <Stack ref={msgStack} justifyContent="flex-end" alignItems="stretch" sx={{minHeight:'calc(100vh - 140px)'}}>
                {msgStore.rmsgs.map((id, i, arr) => (
                    <Msg key={id} msg={msgStore.msgs[id]} prev={i ? msgStore.msgs[arr[i - 1]] : null}/>
                ))}
            </Stack>
        </Box>
        <Box direction="column">
            <Muted muted={muted} unmute={() => mute(0)}/>
            <TxArea>
                <FileTx {...{dropZoneRef, toSend: filesToSend, setToSend: setFilesToSend}} />
                {send ? '' : <PendingConnection>Connecting…</PendingConnection>}
                <TextField ref={msgInput}
                           multiline
                           sx={{width:'100%', background: '#222'}}
                           inputProps={{style: {paddingRight: '40px'}}}
                           placeholder={'Write a message…'}
                           onKeyDown={kd}
                           onKeyUp={ku}
                           autoFocus={true}
                           value={msgTxt}
                           onChange={(e) => setMsgTxt(e.target.value)}
                />
            </TxArea>
        </Box>
    </Box>
}
