import {useToastStore} from "../Toast"
import {getServer} from './server'
import {useCallback} from "react";
const server = getServer()

export function useApiCallback(method, path, {data, setLoading, onDone, ignoreErrors}) {
    const errorToast = useToastStore((state) => state.showError)

    return useCallback((altdata=null) => {
        let txdata = data
        if (altdata !== null) {
            txdata = altdata
        }
        if (!path) return () => {}
        setLoading && setLoading(true)
        const controller = new AbortController()
        const opts = {
            method: method,
            signal: controller.signal,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            mode: 'cors',
        }

        if (window.location.hostname === 'localhost.dea.aero')
            opts.credentials = 'include'

        if (txdata && opts.method !== 'GET') {
            opts.body = JSON.stringify(txdata)
        }

        let url = path.match(/^https?:/) ? path : server + path
        if (altdata && opts.method === 'GET') {
            url += '?' + new URLSearchParams(altdata).toString()
        }
        fetch(url, opts)
            .then((response) => {
                setLoading && setLoading(false)
                response.json().then(data => {
                    if (!response.ok) {
                        data = JSON.stringify((data.hasOwnProperty('data')) ? data.data : data)
                        if (!ignoreErrors)
                            errorToast(data)
                        return
                    }
                    if (onDone) {
                        onDone(data.data, txdata)
                    }
                }, (e) => {
                    setLoading && setLoading(false)
                    console.error(e)
                })
            }, (e) => {
                setLoading && setLoading(false)
                console.error(e)
            })

        return () => {
            console.log('aborting request', method, path, data)
            controller.abort()
        }
    }, [method, setLoading, onDone, path, errorToast, data])
}
