import {styled} from "@mui/system";

const TxArea = styled('div')(() => ({
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
}));

export default TxArea
