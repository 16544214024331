import React, {useState, useEffect, useCallback, useRef} from "react";
import Stack from "@mui/material/Stack";
import {useNavigate} from "react-router-dom";
import {Button} from "@mui/material";
import create from "zustand";
import {useMsgStore} from "../utils/useMsgStore";
import {styled} from "@mui/system";
import TextField from "@mui/material/TextField";
import PendingConnection from "./PendingConnection";
import TxArea from "./TxArea";
import {useWebsocketStore} from "../WebSock";
import {useLoginStore} from "../Login";
import {timeSinceToStr} from "../utils/time";
import PersonName from "./PersonName";

export const useRoomStore = create((set) => ({
    rooms: [],
    setRooms: (rooms) => set({ rooms }),
}))

const Unread = styled('div')(() => ({
    display: 'inline-block',
    backgroundColor: '#f55',
    color: '#fff',
    borderRadius: 20,
    width: 25,
    textAlign: 'center',
    marginLeft: 10,
}))

const NoMsgs = styled('div')(() => ({
    position: 'relative',
    fontStyle: 'italic',
    color: '#666',
}))

export function LoadRoomList() {
    const ws = useWebsocketStore();
    const loginstore = useLoginStore()
    const [loaded, setLoaded] = useState(false)
    useEffect(() => {
        if (loaded) return
        if (!loginstore.user) return
        if (!ws.send) return
        ws.send(JSON.stringify({listrooms: 1}))
        setLoaded(true)
    }, [ws.send, loaded, setLoaded, loginstore.user])
    return <></>
}

const RoomButton = (params) => {
    const {room} = params
    const {last_msgs, unread} = useMsgStore()
    const navigate = useNavigate()
    const [lastMsg, setLastMsg] = useState(<></>)
    const msg = last_msgs[room.id]

    useEffect(() => {
        if (!msg) {
            setLastMsg(<NoMsgs>no messages</NoMsgs>)
            return
        }
        let file = ''

        // let sent = (new Date(msg.rx_at * 1000))
        const now = Math.round(new Date() / 1000)
        const then = Math.round((new Date(msg.rx_at * 1000)) / 1000)
        const sent = timeSinceToStr(now - then)
        if (msg.bin_name) {
            file = <em>{msg.bin_name} ({msg.bin_size >> 10}KB)</em>
        }
        setLastMsg(<div style={{position:'relative'}}>
            <div style={{whiteSpace: 'nowrap', paddingRight:35, overflow:'hidden', textOverflow:'ellipsis'}}>
                <span style={{color:'#aaf'}}>
                    <PersonName person={msg.sender}/>:&nbsp;
                </span>
                {msg.msg}
                {file}
            </div>
            <span style={{position:'absolute', right:0, top:0}}>{sent}</span>
        </div>)
    }, [msg, setLastMsg, last_msgs])

    const [unreadElem, setUnreadElem] = useState('')
    useEffect(() => {
        const ct = unread[room.id] || 0
        if (!ct) {
            setUnreadElem('')
            return
        }
        setUnreadElem(<Unread>{ct}</Unread>)
    }, [unread, setUnreadElem])

    return <Button style={{
        display:'block',
        backgroundColor:'#333',
        border:'1px solid #444',
        padding: 5,
        borderRadius: 0,
        textAlign: 'left',
        textTransform: 'none',
    }} onClick={() => navigate(`/r/${room.id}`)}><strong>{room.name}</strong>{unreadElem}<br/>{lastMsg}</Button>
}

export default function RoomList() {
    const {rooms} = useRoomStore()
    const {last_msgs, setRoomId} = useMsgStore()
    const [sortedRooms, setSortedRooms] = useState([])
    const {send} = useWebsocketStore()
    const [msgTxt, setMsgTxt] = useState('')
    const msgInput = useRef()
    const navigate = useNavigate()

    /**
     * When typing into the message box, the return key triggers a send.
     * Messages with no file attachment and no message content cannot be sent.
     */
    const kd = useCallback((e) => {
        if (e.keyCode !== 13) return
        if (!msgTxt) return
        if (sortedRooms.length === 1) {
            navigate(`/r/${sortedRooms[0].id}`)
        }
    }, [msgTxt, sortedRooms])

    useEffect(() => setRoomId(null), [])

    useEffect(() => {
        // sort rooms list by recency
        let vrooms = rooms.sort((a, b) => {
            if (a.id === 0) return -1  // Lobby always at the top
            if (b.id === 0) return 1  // lobby always at the top
            if (!last_msgs[a.id] || !last_msgs[b.id]) return 0
            if (last_msgs[a.id].rx_at === last_msgs[b.id].rx_at) return 0
            return last_msgs[a.id].rx_at > last_msgs[b.id].rx_at ? -1 : 1
        })

        if (msgTxt && msgTxt.slice(0, 1) !== '/') {
            vrooms = vrooms.filter(f => f.name.toLowerCase().match(msgTxt.toLowerCase()))
        }
        setSortedRooms(vrooms)
    }, [rooms, last_msgs, msgTxt, setSortedRooms])

    return <Stack>
        <Stack direction="column" justifyContent="flex-start" alignItems="stretch" spacing={0}>
            {sortedRooms.map(room => <RoomButton key={room.id} room={room}/>)}
        </Stack>
        <TxArea>
            {send ? '' : <PendingConnection>Connecting...</PendingConnection>}
            <TextField ref={msgInput}
                       sx={{width:'100%', background: '#222'}}
                       placeholder={'Type to filter or run cmd...'}
                       onKeyDown={kd}
                       autoFocus={true}
                       value={msgTxt}
                       onChange={(e) => setMsgTxt(e.target.value)}
            />
        </TxArea>
    </Stack>
}
