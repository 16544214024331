import {useLoginStore} from "../Login";
import {useWebsocketStore} from "../WebSock";
import React, {useEffect, useState} from "react";
import {usePeopleStore} from "../utils/usePeopleStore";


export default function PersonName({person}) {

    const {id} = useLoginStore()
    const from_me = id === person
    const {send} = useWebsocketStore()
    const {people, setPerson} = usePeopleStore()
    const [name, setName] = useState(person)

    useEffect(() => {
        if (id === person) {
            setName('Me')
            return
        }
        const n = people[person]?.name
        setName(n ? n : '…')
    }, [id, people, setName])

    useEffect(() => {
        if (from_me) return
        if (!send) return
        if (people.hasOwnProperty(person)) {
            setName(people[person].name)
            return
        }

        // cache-store the temporary person object
        setPerson({id: person, name: 'loading…'})

        // fetch the person
        send(JSON.stringify({req_person: person}))

    }, [send, people[person]])

    return <span>{name}</span>
}