import useWebSocket, {ReadyState} from 'react-use-websocket';
import {getServer} from "./utils/server";
import {useEffect, useState} from "react";
import {useLoginStore} from "./Login";
import create from "zustand";
import {useMsgStore} from "./utils/useMsgStore";
import {useRoomStore} from "./panel/RoomList";
import {usePeopleStore} from "./utils/usePeopleStore";

const srv = getServer().replace('https://', '')
const socketUrl = `wss://${srv}/ws/status`

export const useServerStore = create((set) => ({
    servertime: 0,
    setServertime: (servertime) => set({ servertime }),
}))

export const useWebsocketStore = create((set) => ({
    send: null,
    setSend: (send) => set({ send }),
}))


export default function WebSock() {
    const loginstore = useLoginStore()
    const roomstore = useRoomStore()
    const peopleStore = usePeopleStore()
    const serverstore = useServerStore()
    const msgStore = useMsgStore()
    const websocketStore = useWebsocketStore()
    const {sendMessage, lastMessage, readyState} = useWebSocket(socketUrl, {
        shouldReconnect: e => true,
        reconnectInterval: 1000,
        reconnectAttempts: 999999,
        retryOnError: 1
    });

    const {send} = websocketStore

    useEffect(() => {
      const connectionStatus = {
        [ReadyState.CONNECTING]: 'Connecting',
        [ReadyState.OPEN]: 'Open',
        [ReadyState.CLOSING]: 'Closing',
        [ReadyState.CLOSED]: 'Closed',
        [ReadyState.UNINSTANTIATED]: 'Uninstantiated',
      }[readyState]
        console.log('ws connection state changed', connectionStatus)
        if (connectionStatus === 'Open') {
            websocketStore.setSend(sendMessage)
        } else {
            websocketStore.setSend(null)
        }
    }, [readyState])

    /**
     * on reconnect, request unread counts
     */
    useEffect(() => {
        if (send)
            sendMessage(JSON.stringify({'unread':1}))
    }, [send])

    /**
     * When uploading a file, retry continuously
     */
    const [uploadCheckInterval, setUploadCheckInterval] = useState(0)
    useEffect(() => {
        const x = setTimeout(() => setUploadCheckInterval(uploadCheckInterval + 1), 1_000)
        return () => clearTimeout(x)
    }, [uploadCheckInterval])

    /**
     * Downloading parts of binary files - throttled
     */
    useEffect(() => {
        if (!send) return // dont try to request anything if websocket connection is down
        const msgwaitlimit = 100
        let msgs_waiting = 0
        const rerequest = {}
        const rerequest_after_secs = 30
        const now = Math.floor(new Date().getTime() / 1000)
        Object.keys(msgStore.file_waiting_parts).every(msgid => {
            msgid = parseInt(msgid)
            if (msgs_waiting >= msgwaitlimit) {
                // message limit reached, must wait for existing bin_parts to be received
                return false // break
            }
            if (msgStore.paused.indexOf(msgid) > -1) {
                return true  // keep looking
            }
            const fparts = msgStore.file_waiting_parts[msgid]
            let check_more = true
            Object.keys(fparts).every(num => {
                const fpart = msgStore.file_waiting_parts[msgid][num]
                if (msgs_waiting >= msgwaitlimit) {
                    // message limit reached, must wait for existing bin_parts to be received
                    // or re-request them after timeout
                    // console.log('message part rx wait limit reached, waiting...')
                    check_more = false
                    return false // break
                }
                if (fpart.requested_at + rerequest_after_secs > now) {
                    // part already requested recently
                    msgs_waiting += 1
                    return true  // keep looking
                }
                msgs_waiting += 1
                msgStore.file_waiting_parts[msgid][num].requested_at = now
                if (typeof (rerequest[msgid]) === 'undefined') {
                    rerequest[msgid] = [num]
                } else {
                    rerequest[msgid].push(num)
                }
                return true  // keep looking
            })
            return check_more
        })
        Object.keys(rerequest).forEach(msgid => {
            msgid = parseInt(msgid)
            // console.log(now, 'requesting', rerequest[msgid].length, 'parts for', msgid, rerequest[msgid].join(','))
            send(JSON.stringify({
                msg: msgid,
                req_bin_parts: rerequest[msgid],
            }))
        })
        // console.log(now, 'paused:', msgStore.paused)
        // console.log(now, 'file_waiting_parts:', JSON.stringify(msgStore.file_waiting_parts))
    }, [send, msgStore.file_waiting_parts, uploadCheckInterval, msgStore.paused])

    /**
     * Uploading parts of binary files - this should be throttled so we dont exceed a specific kbps rate
     * When we send a file part to the server, we get a 'part_ack' in response so we know which parts have been
     * received.
     */
    useEffect(() => {
        if (!send) return // dont try to send anything if websocket connection is down
        const now = Math.floor(new Date().getTime() / 1000)
        // calculated, if we only ever have 100 parts awaiting server ack, then on a 600ms latency connection
        // this should de-facto limit the throughput to about 120kbps, assuming packet size of 1500
        const msgwaitlimit = 100
        let msgs_waiting = 0
        const chunkSize = 1390;
        // upload all thumbnail images first
        [true, false].forEach(thumbs_first => {
            msgStore.file_tx_parts.some(txmsg => {
                const msgid = txmsg.msg.id
                if (typeof msgid === 'undefined' || !msgid) {
                    // should alert on this and probably remove the file part
                    return false
                }
                if (msgs_waiting >= msgwaitlimit) {
                    // message limit reached, must wait for existing messages to be ack'd
                    // or re-send them after timeout
                    // console.log('message part tx wait limit reached, waiting...')
                    return true // break
                }
                if (thumbs_first && typeof txmsg.msg?.thumb_for === 'undefined') {
                    return false
                }
                if (msgStore.paused.indexOf(txmsg.msg.id) > -1) {
                    return false
                }
                Object.keys(txmsg.parts).some(num => {
                    const fpart = txmsg.parts[num]
                    if (msgs_waiting >= msgwaitlimit) {
                        return true
                    }
                    if (fpart.sent_at > now - 30) {
                        // part already sent recently
                        msgs_waiting += 1
                        return false
                    }
                    if (typeof txmsg.data !== 'object') {
                        console.error('txmsg.data is empty', txmsg)
                        return false
                    }
                    // data is a File object here.
                    // we're only splitting the specific chunk of data we need from the file itself
                    // right before we send it, so we dont have thousands of file chunks sitting around in memory
                    num = parseInt(num)
                    const data = txmsg.data.slice(num * chunkSize, (num + 1) * chunkSize)
                    const blob = new Blob(['b', msgid, '.', num, '.', data])

                    // if (fpart.sent_at) {
                    //     console.log('re-sending file part', {...fpart, bytes:fpart.data.length, data:null})
                    // } else {
                    //     console.log('sending file-part', {...fpart, bytes:fpart.data.length, data:null})
                    // }
                    fpart.sent_at = Math.floor(new Date().getTime() / 1000)
                    send(blob)
                    msgs_waiting += 1
                    return false
                })
            })
        })
    }, [send, msgStore.file_tx_parts, uploadCheckInterval, msgStore.paused])

    /**
     * Process messages received via websocket
     */
    useEffect(() => {
        if (lastMessage === null) return

        if (lastMessage.data instanceof Blob) {
            const dd = lastMessage.data
            dd.slice(1, 20).text().then(t => {
                // binary message received
                let [msg, num] = t.split('.', 2)
                const msg_bin = dd.slice(('b' + msg + '.' + num + '.').length)
                msg = parseInt(msg)
                num = parseInt(num)
                msgStore.rx_file_part({msg, num, msg_bin})
            })
            return
        }

        const data = JSON.parse(lastMessage.data)
        if (typeof data.roomlist === 'object') {
            roomstore.setRooms(data.roomlist)
            data.roomlist.forEach(r => {
                if (!r.last_message)
                    return
                console.log('setting last msg', r.last_message)
                msgStore.add_msg({...r.last_message, room: r.id})
            })
            return
        }

        if (typeof data.servertime === 'number') {
            serverstore.setServertime(data.servertime)
            return
        }

        if (typeof data.bin_state === 'object') {
            // notification that the state of a binary upload has changed
            msgStore.set_bin_state(data.bin_state)
        }

        if (typeof data.unread === 'object') {
            msgStore.set_unread(data.unread)
            if (typeof data.read === 'object') {
                msgStore.set_read(data.read)
            }
            return
        }

        if (typeof data.joinedroom === 'object') {
            if (!roomstore.rooms.filter(r => r.id === data.joinedroom.id).length) {
                roomstore.setRooms([...roomstore.rooms, data.joinedroom])
            }
            return
        }

        if (typeof data.leftroom === 'object') {
            roomstore.setRooms(
                roomstore.rooms.filter(r => r.id !== data.leftroom.id)
            )
            return
        }

        if (typeof data.ack === 'object') {
            if (data.ack.hasOwnProperty('part')) {
                msgStore.ack_part(data.ack)
            } else {
                msgStore.ack(data.ack)
            }
            return
        }

        // This is for a collection of one or more messages, probably historical ones or just loading the current
        // room chat history, no alerts or notifications to be raised for these messages
        if (typeof data.msgs === 'object') {
            try {
                msgStore.add_msg_bulk(data.msgs)
            } catch (e) {
                console.error('data.msgs', e)
            }
            return
        }

        if (typeof data.person === 'object') {
            try {
                peopleStore.setPerson(data.person)
            } catch (e) {
                console.error('data.person', e)
            }
            return
        }

        // A new message has arrived, maybe raise a notification
        if (typeof data.newmsg === 'object') {
            try {
                msgStore.add_msg(data.newmsg)
            } catch (e) {
                console.error('data.newmsg', e)
            }

            // we raise a ping noise to alert the user to a new message, only when its actually been received.
            const shouldPing = (msg) => {
                if (msg.sender === msgStore.user_id) {
                    // if the sender is the current user, do not ping
                    return 0
                }
                if (msg.room !== msgStore.roomid) {
                    // if message is to room not in focus, ping
                    return 1
                }
                if (!document.hasFocus()) {
                    // if the chat window is not in focus, ping
                    return 1
                }
                return 0
            }
            shouldPing(data.newmsg) && (new Audio('/ping.m4a')).play()

            return
        }

        if (typeof data.identity === 'object' && data.identity !== null) {
            console.log('ws setting identity', data.identity)
            if (data.identity.hasOwnProperty('id'))
                loginstore.setId(data.identity.id)
            if (data.identity.hasOwnProperty('image'))
                loginstore.setImage(data.identity.image)
        }

    }, [lastMessage]);

    return (
        <></>
    );
}